import axios from "./axios";
import {
  getAccountsQuery,
  getAccountTypesQuery,
  getLabelsQuery,
  getJournalEntryQuery,
  getJournalEntriesQuery,
  getEntryTypesQuery,
} from "./graphql";
import moment from "moment";

// authService
//   .getAccessToken()
//   .then(
//     (token) =>
//       (axios.defaults.headers.common["Authorization"] = "Bearer " + token)
//   );

export const nextVoucherNumber = async (args) =>
  await axios.get(`/api/vouchers/next?year=${args.year}`);

export const getAccounts = async () =>
  await axios.post("/graphql", {
    query: getAccountsQuery(),
  });

export const getAccountTypes = async () =>
  await axios.post("/graphql", {
    query: getAccountTypesQuery(),
  });

export const createAccount = async (account) =>
  await axios.post("/api/accounts", account);

export const deleteAccount = async (id) =>
  await axios.delete(`/api/accounts/${id}`);

export const getLabels = async () =>
  await axios.post("/graphql", {
    query: getLabelsQuery(),
  });

export const createLabel = async (account) =>
  await axios.post("/api/labels", account);

export const deleteLabel = async (id) =>
  await axios.delete(`/api/labels/${id}`);

export const getJournalEntry = async (id) =>
  await axios.post("/graphql", {
    query: getJournalEntryQuery(id),
  });

export const getJournalEntries = async (args) => {
  console.log("getJournalEntries", args);
  const { from, to, labels, contraAccounts, accounts, page, perPage = 10 } = args;
  console.log("moment(to).toISOString()", to, moment(to).toISOString());
  return await axios.get(
    `/api/journalentries?from=${from ? moment(from).format() : ""}&to=${
      to ? moment(to).toISOString() : ""
    }&labels=${labels || ""}&contraAccounts=${contraAccounts || ""}&accounts=${
      accounts || ""
    }&page=${page}&per_page=${perPage}`
  );
};

export const sumJournalEntries = async (args) => {
  const { from, to, labels, contraAccounts, accounts, page, perPage } = args;
  return await axios.get(
    `/api/journalentries/sum?from=${from ? moment(from).format() : ""}&to=${
      to ? moment(to).toISOString() : ""
    }&labels=${labels || ""}&contraAccounts=${contraAccounts || ""}&accounts=${
      accounts || ""
    }&page=${page}&per_page=${perPage}`
  );
};

export const getEntryTypes = async () =>
  await axios.post("/graphql", {
    query: getEntryTypesQuery(),
  });

export const createJournalEntry = async (entry) =>
  await axios.post("/api/journalentries", entry);

export const updateJournalEntry = async (id, entry) =>
  await axios.put(`/api/journalentries/${id}`, entry);

export const deleteJournalEntry = async (id) =>
  await axios.delete(`/api/journalentries/${id}`);

export const getReport = async (selectedYear, labels) => {
  //   await axios.get(`/api/reports?name=${key}`);
  axios({
    url: `/api/reports?name=&selectedYear=${selectedYear}&labels=${
      labels ? labels : ""
    }`,
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Bericht.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};

export const getYTDBalance = async () =>
  await axios.get(`/api/aggregations/balance`);

export const voucherUnused = async (v) =>
  await axios.head(`/api/vouchers/${v}`);
