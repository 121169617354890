import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getJournalEntry as fetchJournalEntry,
  getJournalEntries as fetchJournalEntries,
  sumJournalEntries as _sumJournalEntries,
  createJournalEntry as createNewJournalEntry,
  deleteJournalEntry as removeJournalEntry,
  updateJournalEntry as editJournalEntry,
  getEntryTypes as fetchEntryTypes,
  nextVoucherNumber as nextVoucherNr,
  getYTDBalance as fetchYTDBalance,
} from "../../services/api";

const initialState = {
  loading: false,
  journalEntry: null,
  journalEntries: [],
  journalEntriesTotal: 0,
  entryTypes: [],
  nextVoucherNr: 0,
  ytdBalance: [],
};

const getYTDBalance = createAsyncThunk(
  "journalEntries/getYTDBalance",
  async (args) => {
    const res = await fetchYTDBalance(args);
    return res.data;
  }
);

const getJournalEntry = createAsyncThunk(
  "journalEntries/getJournalEntry",
  async (args) => {
    const res = await fetchJournalEntry(args);
    return res.data;
  }
);

const getNextVoucherNr = createAsyncThunk(
  "journalEntries/getNextVoucherNr",
  async (args) => {
    const res = await nextVoucherNr(args);
    return res.data;
  }
);

const sumJournalEntries = createAsyncThunk(
  "journalEntries/sumJournalEntries",
  async (args) => {
    console.log("journalEntries/sumJournalEntries");
    const res = await _sumJournalEntries(args);
    console.log("res", res);
    return res;
  }
);

const getJournalEntries = createAsyncThunk(
  "journalEntries/getJournalEntries",
  async (args) => {
    console.log("journalEntries/getJournalEntries");
    const res = await fetchJournalEntries(args);
    console.log("res", res);
    return res;
  }
);

const getEntryTypes = createAsyncThunk(
  "journalEntries/getEntryTypes",
  async (args) => {
    const res = await fetchEntryTypes(args);
    return res.data;
  }
);

const createJournalEntry = createAsyncThunk(
  "journalEntries/createJournalEntry",
  async (args) => {
    console.log("createAsyncThunk", args);
    const res = await createNewJournalEntry(args);
    return res.data;
  }
);

const updateJournalEntry = createAsyncThunk(
  "journalEntries/updateJournalEntry",
  async (args) => {
    const { id, entry } = args;
    const res = await editJournalEntry(id, entry);
    return res.data;
  }
);

const deleteJournalEntry = createAsyncThunk(
  "journalEntries/deleteJournalEntry",
  async (id) => {
    await removeJournalEntry(id);
    return id;
  }
);

const journalEntriesSlice = createSlice({
  name: "journalEntries",
  initialState,
  extraReducers: {
    [getYTDBalance.pending]: (state, action) => {
      state.loading = true;
    },
    [getYTDBalance.fulfilled]: (state, action) => {
      state.loading = false;
      state.ytdBalance = action.payload;
    },
    [getJournalEntry.pending]: (state, action) => {
      state.loading = true;
    },
    [getJournalEntry.fulfilled]: (state, action) => {
      const { journalEntry: data } = action.payload.data;
      state.journalEntry = data;
      state.loading = false;
    },
    [getJournalEntries.fulfilled]: (state, action) => {
      console.log("action.payload.data", action.payload.data);
      state.journalEntries = action.payload.data.data;
      state.journalEntriesTotal = action.payload.data.total;
      state.loading = false;
    },
    [sumJournalEntries.fulfilled]: (state, action) => {
      console.log("action.payload.data", action.payload.data);
      state.sum = action.payload.data;
      state.loading = false;
    },
    [createJournalEntry.fulfilled]: (state, action) => {
      state.journalEntries.push(action.payload);
      state.loading = false;
    },
    [deleteJournalEntry.fulfilled]: (state, action) => {
      state.journalEntries = state.journalEntries.map(function (item) {
        if (item.id === action.payload) item.deleted = true;
        return item;
      });
      state.loading = false;
    },
    [updateJournalEntry.pending]: (state, action) => {
      state.loading = true;
    },
    [updateJournalEntry.fulfilled]: (state, action) => {
      state.loading = false;
      state.journalEntry = null;
    },
    [getEntryTypes.fulfilled]: (state, action) => {
      const { entryTypes: data } = action.payload.data;
      state.entryTypes = data;
      state.loading = false;
    },
    [getNextVoucherNr.fulfilled]: (state, action) => {
      state.nextVoucherNr = action.payload.nr;
      state.loading = false;
    },
  },
});

export {
  getJournalEntry,
  getJournalEntries,
  sumJournalEntries,
  createJournalEntry,
  updateJournalEntry,
  deleteJournalEntry,
  getEntryTypes,
  getNextVoucherNr,
  getYTDBalance,
};
export default journalEntriesSlice.reducer;
