// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "@src/redux/reducers/auth";
import navbar from "@src/redux/reducers/navbar";
import layout from "@src/redux/reducers/layout";
import chat from "@src/views/apps/chat/store/reducer";
import todo from "@src/views/apps/todo/store/reducer";
import users from "@src/views/apps/user/store/reducer";
import email from "@src/views/apps/email/store/reducer";
import invoice from "@src/views/apps/invoice/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import ecommerce from "@src/views/apps/ecommerce/store/reducer";
import dataTables from "@src/views/tables/data-tables/store/reducer";
import accounts from "./slices/accountsSlice";
import labels from "./slices/labelsSlice";
import journalEntries from "./slices/journalEntriesSlice";
import reports from "./slices/reportsSlice";

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  accounts,
  labels,
  journalEntries,
  reports,
});

export default rootReducer;
