import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReport as fetchReport } from "../../services/api";

const initialState = {
  loading: false,
};

const getReport = createAsyncThunk("labels/getReport", async (args) => {
  const res = await fetchReport(args);
  return res.data;
});

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  extraReducers: {},
});

export { getReport };
export default reportsSlice.reducer;
