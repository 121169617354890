import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getLabels as fetchLabels,
    createLabel as createNewLabel,
    deleteLabel as removeLabel
} from '../../services/api';

const initialState = {
    loading: false,
    labels: [],
    labelTypes: [],
};

const getLabels = createAsyncThunk('labels/getLabels', async (args) => {
    const res = await fetchLabels(args);
    return res.data;
});

const createLabel = createAsyncThunk('labels/createLabel', async (args) => {
    const res = await createNewLabel(args);
    return res.data;
});

const deleteLabel = createAsyncThunk('labels/deleteLabel', async (id) => {
    await removeLabel(id);
    return id;
});

const labelsSlice = createSlice({
    name: 'labels',
    initialState,
    extraReducers: {
        [getLabels.fulfilled]: (state, action) => {
            const { labels: data } = action.payload.data;
            state.labels = data;
            state.loading = false;
        },
        [createLabel.fulfilled]: (state, action) => {
            state.labels.push(action.payload);
            state.loading = false;
        },
        [deleteLabel.fulfilled]: (state, action) => {
            state.labels = state.labels.filter(function(e) { return e.id !== action.payload })
            state.loading = false;
        }
    },
});

export { getLabels, createLabel, deleteLabel };
export default labelsSlice.reducer;
