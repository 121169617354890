export const getAccountsQuery = () => `{
    accounts(sort: { typeId: ASC, code: ASC }) {
        id
        code
        name
        type
    }
}`;

export const getAccountTypesQuery = () => `{
    accountTypes
}`;

export const getLabelsQuery = () => `{
    labels {
        id
        text
        color
    }
}`;

export const getJournalEntriesQuery = (from, to, labels) => `{
    journalEntries(sort: { date: DESC }) {
        id
        voucherNumber
        amount
        text
        date
        type
        deleted
        deleteDate
        account {
            code
            name
            type
        }
        contraAccount {
            code
            name
            type
        }
    }
}`;

export const getJournalEntryQuery = (id) => `{
    journalEntry(id: ${id}) {
        id
        voucherNumber
        amount
        text
        date
        type
        comment
        labels
        deleted
        deleteDate
        createDate
        createUser
        account {
            id
            code
            name
            type
        }
        contraAccount {
            id
            code
            name
            type
        }
    }
}`;

export const getEntryTypesQuery = () => `{
    entryTypes
}`;
