import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAccounts as fetchAccounts,
  getAccountTypes as fetchAccountTypes,
  createAccount as createNewAccount,
  deleteAccount as removeAccount,
} from "../../services/api";

const initialState = {
  loading: false,
  accounts: [],
  assetAccounts: [],
  contraAccounts: [],
  accountTypes: [],
};

const getAccounts = createAsyncThunk("accounts/getAccounts", async (args) => {
  const res = await fetchAccounts(args);
  return res.data;
});

const getAccountTypes = createAsyncThunk(
  "accounts/getAccountTypes",
  async (args) => {
    const res = await fetchAccountTypes(args);
    return res.data;
  }
);

const createAccount = createAsyncThunk(
  "accounts/createAccount",
  async (args) => {
    const res = await createNewAccount(args);
    return res.data;
  }
);

const deleteAccount = createAsyncThunk("accounts/deleteAccount", async (id) => {
  await removeAccount(id);
  return id;
});

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  extraReducers: {
    [getAccounts.fulfilled]: (state, action) => {
      const { accounts: data } = action.payload.data;
      state.accounts = data;
      state.assetAccounts = data.filter(
        (account) => account.type.code === "BESTAND"
      );
      state.contraAccounts = data.filter(
        (account) =>
          // account.type.code === "AUFWAND" || account.type.code === "ERTRAG"
          true // return all accounts
      );
      state.loading = false;
    },
    [getAccountTypes.fulfilled]: (state, action) => {
      const { accountTypes: data } = action.payload.data;
      state.accountTypes = data;
      state.loading = false;
    },
    [createAccount.fulfilled]: (state, action) => {
      state.accounts.push(action.payload);
      state.loading = false;
    },
    [deleteAccount.fulfilled]: (state, action) => {
      state.accounts = state.accounts.filter(function (e) {
        return e.id !== action.payload;
      });
      state.loading = false;
    },
  },
});

export { getAccounts, getAccountTypes, createAccount, deleteAccount };
export default accountsSlice.reducer;
