/**
 * Create axios instance with default configs
 */
// import axios from "axios";
import useJwt from "@src/@core/auth/jwt/useJwt";
import axios from "axios";

const { jwt } = useJwt(axios, {});

const baseURL = process.env.REACT_APP_API_ENDPOINT; // Graphql mock-server use process.env.REACT_APP_MOCK_ENDPOINT, page url use REACT_APP_API_ENDPOINT

console.log(baseURL);
// const instance = axios.create({
//   baseURL,
// });
// Set config defaults when creating the instance
const instance = jwt.getAxiosInstance();
//  instance.interceptors.response.use(
//      (response) => {
//          return response
//      },
//      (error) => {
//          if (error.response.status === 401) {
//              console.log('oh oh - got 401')
//              // redirect to Login
//              window.location.href = '/login'
//              return error
//          }
//          throw error
//      }
//  )

export { instance as axios };
export default instance;
